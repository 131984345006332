import axios from './_axios'
import {
  auth,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  browserLocalPersistence,
  setPersistence,
  updatePassword,
  where,
  functionsEndpoint,
  createUserWithEmailAndPassword,
} from '../firebase';
import { dbFindAllUtil, dbFindUtil } from './fbDbUtils.js';
import {SYSTEM_ROLES} from "../utils/consts";

const FUNCTIONS_ENDPOINT = functionsEndpoint

const STORAGE_KEY = 'scolados-dash-fb';

export function getAccessToken() {
  return auth.currentUser?.accessToken || ""
}

export function getUser() {
  let user = localStorage.getItem(STORAGE_KEY);
  if (!user) {
    user = sessionStorage.getItem(STORAGE_KEY);
  }
  if (user) {
    return JSON.parse(user);
  } else {
    return null;
  }
}

export function isAuthenticated() {
  const user = getUser();
  return user && user.role;
}

export function getUserRole() {
  const user = getUser();
  if (user) {
    return user.role;
  }
  return null
}

export async function doLogin(userEmail, userPass, rememberMe) {
  if (rememberMe) {
    await setPersistence(auth, browserLocalPersistence);
  }
  const loginResult = await signInWithEmailAndPassword(auth, userEmail, userPass);
  const userLogged = await dbFindUtil(`/users/${loginResult.user.uid}`);

  if (rememberMe) {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(userLogged));
  } else {
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(userLogged));
  }
  return userLogged
}

export async function doLogout() {
  // await signOut(auth);
  await auth.signOut()
  localStorage.removeItem(STORAGE_KEY);
}

export async function doResetPassword(userEmail) {
  try {
    await sendPasswordResetEmail(auth, userEmail);
    return 'OK';
  } catch (e) {
    console.log(`reset password ERROR: ${e.message}`);
    throw e;
  }
}

export async function getUsersFromDb() {
  return await dbFindAllUtil('users');
}

export async function getUsersBySchoolFromDb(schoolId){
  return await dbFindAllUtil('users', where('schools', 'array-contains', schoolId));
}

export async function getUserByEmail(email){
  const emails = await dbFindAllUtil('users', where('email', '==', email));
  return emails[0] ? emails[0] : null
}

export async function getUsersByRole(role) {
  return await dbFindAllUtil('users', where("role", "==", role))
}

/**
 *
 * @param {*} roleName
 * @returns true se o usuário logado possui a role com o nome roleName
 *          false se o usuário não está logado ou não possui a role
 */
export function userHasRole(user, roleName) {
  return user.role && user.role === roleName;
}

export function isSysAdmin(user) {
  return userHasRole(user, SYSTEM_ROLES.ADM_SYSTEM);
}

export async function getUserLoggedFromDb() {
  const user = getUser();
  return user;
}

//@deprecated
export async function doEditUser(userName, userEmail, roles, schools, disabled) {
  const userToDb = {
    userName,
    email: userEmail,
    role: roles,
    schools,
    disabled,
  };

  await axios.put(`${FUNCTIONS_ENDPOINT}/users/editUser`, { user: userToDb });
  return 'OK';
}

export async function editUser(userId, user) {
  await axios.put(`${FUNCTIONS_ENDPOINT}/users/${userId}`, user)
  return 'OK'
}

export async function getUserById(userId) {
  return await dbFindUtil(`users/${userId}`);
}

export function editStorageUser(userName) {
  const user = localStorage.getItem(STORAGE_KEY);
  const newUser = { ...user, userName };
  localStorage.setItem(STORAGE_KEY, JSON.stringify(newUser));
}

export async function doUpdatePassword(newPassword) {
  try {
    await updatePassword(auth.currentUser, newPassword);
    return 'OK'
  } catch (e) {
    console.log(`update password ERROR: ${e.message}`);
    throw e;
  }
}

