import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full-layout/loadable/Loadable';
import {getUser, getUserRole, isAuthenticated} from '../services/userService';
import {SYSTEM_ROLES} from "../utils/consts";

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full-layout/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank-layout/BlankLayout')));
/* ***End Layouts**** */

const SchoolHome = Loadable(lazy(() => import('../views/school/SchoolHome')));
const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Forbidden = Loadable(lazy(() => import('../views/authentication/Forbidden')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const Register = Loadable(lazy(() => import('../views/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../views/authentication/ResetPassword')));
const SchoolChoose = Loadable(lazy(() => import('../views/landing/SchoolChoose')));
const BackToSchoolList = Loadable(lazy(() => import('../views/list/BackToSchoolList')));
const SharingList = Loadable(lazy(() => import('../views/sharingList/SharingList')));
const Profile = Loadable(lazy(() => import('../views/profile/Profile')));
const ManagerSchool = Loadable(lazy(() => import('../views/school/ManagerSchool')));
const Onboarding = Loadable(lazy(() => import('../views/onboarding/Onboarding')));
const MailConfirmation = Loadable(lazy(() => import('../views/onboarding/MailConfirmation')));
const MyLists = Loadable(lazy(() => import('../views/MyLists/MyLists')));
const Records = Loadable(lazy(() => import('../views/record/Records')));
const Publisher = Loadable(lazy(() => import('../views/publisher/Publisher')));
const CustomTimeline = Loadable(lazy(() => import('../views/timeline/CustomTimeline')));
const CustomerLists = Loadable(lazy(() => import('../views/apps/customers/CustomerLists')));
const ListManagement = Loadable(lazy(() => import('../views/listManagement/ListManagement')));

export function ProtectedRoute({ children, allowedRoles }) {
  if (!isAuthenticated()) {
    return <Navigate to="/auth/login" replace />;
  }

  const userRole = getUserRole();

  if (allowedRoles && !allowedRoles.includes(userRole)) {
    return <Navigate to="/auth/403" replace />
  }

  return children;
}

const Router = [
  {
    path: '/',
    element: (
        <ProtectedRoute>
          <FullLayout />
        </ProtectedRoute>
    ),
    children: [
      {
        path: '/',
        element: (
            <Navigate
                to={getUserRole() === SYSTEM_ROLES.PUBLISHER_USER ? '/publisher' : '/home'}
                replace
            />
        ),
      },
      {
        path: '/home',
        exact: true,
        element: (
            <ProtectedRoute allowedRoles={[SYSTEM_ROLES.SCHOOL_USER, SYSTEM_ROLES.ADM_SYSTEM]}> {/* Apenas 'escola' pode acessar */}
              <SchoolChoose />
            </ProtectedRoute>
        ),
      },
      {
        path: '/escola',
        exact: true,
        element: (
            <ProtectedRoute allowedRoles={[SYSTEM_ROLES.SCHOOL_USER, SYSTEM_ROLES.ADM_SYSTEM]}> {/* Apenas 'escola' pode acessar */}
              <SchoolHome />
            </ProtectedRoute>
        ),
      },
      {
        path: '/lista',
        exact: true,
        element: (
            <ProtectedRoute allowedRoles={[SYSTEM_ROLES.SCHOOL_USER, SYSTEM_ROLES.ADM_SYSTEM]}> {/* Apenas 'escola' pode acessar */}
              <BackToSchoolList />
            </ProtectedRoute>
        ),
      },
      {
        path: '/minhas-listas',
        element: (
            <ProtectedRoute allowedRoles={[SYSTEM_ROLES.SCHOOL_USER, SYSTEM_ROLES.ADM_SYSTEM]}> {/* Apenas 'escola' pode acessar */}
              <MyLists />
            </ProtectedRoute>
        ),
      },
      {
        path: '/gerencia-escola',
        element: (
            <ProtectedRoute allowedRoles={[SYSTEM_ROLES.SCHOOL_USER, SYSTEM_ROLES.ADM_SYSTEM]}> {/* Apenas 'escola' pode acessar */}
              <ManagerSchool />
            </ProtectedRoute>
        ),
      },
      {
        path: '/customers/lists',
        exact: true,
        element: (
            <ProtectedRoute allowedRoles={[SYSTEM_ROLES.SCHOOL_USER, SYSTEM_ROLES.ADM_SYSTEM]}> {/* Apenas 'escola' pode acessar */}
              <CustomerLists />
            </ProtectedRoute>
        ),
      },
      {
        path: '/records/:listId',
        exact: true,
        element: (
            <ProtectedRoute allowedRoles={[SYSTEM_ROLES.SCHOOL_USER, SYSTEM_ROLES.ADM_SYSTEM]}> {/* Apenas 'escola' pode acessar */}
              <Records />
            </ProtectedRoute>
        ),
      },
      {
        path: '/meu-perfil',
        exact: true,
        element: (
            <ProtectedRoute> {/* 'escola' e 'editora' podem acessar */}
              <Profile />
            </ProtectedRoute>
        ),
      },
      {
        path: '/publisher',
        exact: true,
        element: (
            <ProtectedRoute allowedRoles={[SYSTEM_ROLES.PUBLISHER_USER, SYSTEM_ROLES.ADM_SYSTEM]}> {/* Apenas 'editora' pode acessar */}
              <Publisher />
            </ProtectedRoute>
        ),
      },
      { path: '*', element: <Navigate to="/auth/404" /> },
      {
        path: 'gerencia-lista/:listId',
        element: (
          <ProtectedRoute allowedRoles={[SYSTEM_ROLES.SCHOOL_USER, SYSTEM_ROLES.ADM_SYSTEM]}>
            <ListManagement />
          </ProtectedRoute>
        )
      }
    ],
  },
  ,
  {
    path: 'auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: '403', element: <Forbidden /> },
      { path: 'login', element: <Login /> },
      { path: 'cadastro/:invitationId', element: <Register /> },
      { path: 'reset-password', element: <ResetPassword /> },
    ],
  },
  {
    path: 'lista-material/:listId',
    element: (
        <ProtectedRoute allowedRoles={[SYSTEM_ROLES.SCHOOL_USER, SYSTEM_ROLES.ADM_SYSTEM]}>
          <SharingList />
        </ProtectedRoute>
    )
  },
  {
    path: 'onBoarding',
    element: <Onboarding />,
  },
  {
    path: 'mailConfirmation/:userId',
    element: <MailConfirmation />,
  },
];

export default Router;
